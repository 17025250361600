import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';


const Home = lazy(() => import('./pages/Home'));
const Cart = lazy(() => import('./pages/Cart'));
const Search = lazy(() => import('./pages/Search'));
const Productdetails = lazy(() => import('./pages/Productdetails'));
const Productdetailssample = lazy(() => import('./pages/Productdetailssample'));
const Myorders = lazy(() => import('./pages/Myorders'));
const Myaccount = lazy(() => import('./pages/Myaccount'));
const Wishlist = lazy(() => import('./pages/Wishlist'));
const BuyerProtection = lazy(() => import('./pages/BuyerProtection'));
const LoginPage = lazy(() => import('./pages/loginpage'));
const Catagorysample = lazy(() => import('./pages/catagorysample'));
const Notification = lazy(() => import('./pages/Notification'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Catagorystore = lazy(() => import('./pages/Catagorystore'));
const Catagory = lazy(() => import('./pages/catagory'));
const Guaranteeddelivery = lazy(() => import('./pages/Guaranteeddelivery'));
const SubcategoryPage = lazy(()=> import('./pages/SubcatagoryPage'))
const Addtocart= lazy(()=> import('./pages/addtocart/Addtocart'))

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (    
    <Router>
      <ScrollToTop/>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/search" element={<Search />} />
          <Route path="/productdetails" element={<Productdetails />} />
          <Route path="/category" element={<Catagory />} />
          <Route path="/productdetailssample" element={<Productdetailssample />} />
          <Route path="/myorders" element={<Myorders />} />
          <Route path="/myaccount" element={<Myaccount />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/buyerprotection" element={<BuyerProtection />} />
          <Route path="/loginpage" element={<LoginPage />} />
          <Route path="/categorysample" element={<Catagorysample />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/guaranteeddelivery" element={<Guaranteeddelivery />} />
          <Route path="/catagorystore" element={<Catagorystore />} />
          <Route path='/subcatagory/:title' element={<SubcategoryPage/>}/>
          <Route path="/addtocart" element={<Addtocart />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;

import axios from 'axios';
import { Configuration, domain } from './Url';


const familyListUrl             = domain()+"/market/buyer/get/family/lists";
const categoryListUrl           = domain()+"/market/buyer/get/category/lists";
const productListUrl            = domain()+"/market/buyer/get/product/list";
const productDetialUrl          = domain()+"/market/buyer/get/product/details";
const productSpecificationUrl   = domain()+"/market/buyer/get/product/sepecifications";
export function familyListApi(){
    let config = Configuration();
    let url = familyListUrl;
    return axios.get(url, config);
}
export function categoryListApi(data){
    let config = Configuration();
    config.params = data
    let url = categoryListUrl;
    return axios.get(url, config);
}
export function productListApi(data){
    let config = Configuration();
    config.params = data
    let url = productListUrl;
    return axios.get(url, config);
}
export function productDetailApi(data){
    let config = Configuration();
    config.params = data;
    let url = productDetialUrl;
    return axios.get(url, config);
}
export function productSpecificationApi(data){
    let config = Configuration();
    config.params = data;
    let url = productSpecificationUrl;
    return axios.get(url, config);
}
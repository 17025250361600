import React from 'react';
import { Link } from 'react-router-dom';

const DesktopSearchResults = ({ discoverMoreItems, homeAppliances, searchResults, showSection, showSection1 }) => (
  <div className="search--active--20ny0_q">
    {showSection && (
      <div className="src--active--dSviezj">
        <section className="src--section--2IyvbsL">
          <div data-spm="discover_more">
            <h3 className="src--title--1Scd3D2">Discover more</h3>
            <ul className="src--listWrap--1fX8jCH">
              {discoverMoreItems.map((item, index) => (
                <li key={index} className="src--item--2XLAtpX">
                  <Link to={item.link}>
                    <span className="src--listTitle--242jquk">{item.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <span className="src--line--3QGw_X6"></span>
          <div data-spm="search_ranking">
            <p className="src--titleWrap--V-lnXin">
              <h3 className="src--title--1Scd3D2">Home Appliances</h3>
              <span>Other recommendations</span>
            </p>
            <div className="src--productContainer--JxCHZix">
              {homeAppliances.map((product, index) => (
                <Link key={index} to={product.link}>
                  <div className="src--product--3mJ4V1B">
                    <div className="src--imgWrap--37UvgxE">
                      <img src={product.image} alt={product.title} />
                    </div>
                    <span className="src--productTitle--1ZKxErJ">{product.title}</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </div>
    )}
    {showSection1 && (
      <div className="src--active--dSviezj src--autoPanel--52V_44o">
        <ul className="src--auto--2EvPUd1">
          {searchResults.map((result, index) => (
            <li key={index}>
              <Link to={result.link}>
                <span className="src--autoItem--9yCOcGQ">
                  <span style={{ fontWeight: 700 }}>{result.text}</span>
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

export default DesktopSearchResults;

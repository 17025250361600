
export function domain() {
    return process.env.REACT_APP_API_URL;
  }
  export function PUBLIC_URLdomain(){
    return process.env.REACT_APP_PUBLIC_URL;
  }
  export function CoreUrl(){
      return domain()+"/api/v1.0/app/core/";
  }
  export function CoreImageUrl(){
      // return "https://ev.public.heptotechnologies.org/core/";
      return PUBLIC_URLdomain()+"/core/";
  }  
  export function marketImageUrl(){
    return PUBLIC_URLdomain()+"/market/";
  }
  export function Configuration(){
    // return  {
    //     headers: { 'Accept': 'application/json', "X-Authuser" : getAuthIdFromUrl() },
    //     withCredentials: true,
    // };  
     return  {
        headers: { 
            'Accept': 'application/json', 
            "X-Authuser" : 0, 
            "X-Request-Agent":"APP",
             "X-SID":"sid_X3bI63WP7VIAkzhXvj75", 
             "X-MUID":""
        },
        withCredentials: true,
    };
}
//   export function countryselectapi() {
//     return domain() + '/market/buyer/get/country/lists';
//   }

//   export function currencyselectapi() {
//     return domain() + '/market/buyer/get/currency/lists';
//   }

//   export function familyListapi() {
//     return domain() + '/market/buyer/get/family/lists';
//   }
  
//   export function catagoryListapi(familyId) {
//     return `${domain()}/market/buyer/get/category/lists?family_id=${familyId}`;
// }

// export function porductlistapi() {
//   return domain() + '/market/buyer/get/product/list';
// }
  